import {
    Box,
    CircularProgress,
    SxProps
} from "@mui/material"

type LoaderType = {
    sx?: SxProps,
    size?: number,
    color?: string
}

function Loader({
    sx,
    size,
    color,
}: LoaderType) {

    return (
        <Box
            sx={{
                pt: 10,
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                ...sx
            }}
        >
            <CircularProgress
                size={size}
                sx={{
                    color,
                }}
            />
        </Box>
    )
}

export default Loader