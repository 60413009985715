import {
  Box,
  Card,
  Typography,
  useTheme
} from '@mui/material';
import {
  useRouter
} from 'next/router';
import ArrowRight from 'public/assets/icons/arrow-right.svg';
import PlayBtnBox from 'public/assets/icons/play-btn-box.svg';
import StopBtnBox from 'public/assets/icons/stop-btn-box.svg';
import {
  useState
} from 'react';
import {
  occasionPath
} from '~config/constants/paths';
import useResponsive from '~hooks/useResponsive';
import AudioPlayer from '~screens/shared/audio-player';
import {
  centerFace
} from '~helper/index'

type CardProps = {
  _id: string
  imagePath: string
  margin?: string
  name?: any
  occasionSong: any
};

function OccasionCard({
  _id,
  name,
  imagePath,
  occasionSong,
}: CardProps) {

  const {
    push
  } = useRouter();

  const {
    palette: {
      common,
      text,
    },
  }: any = useTheme();

  const [togglePlay, setTogglePlay]
    = useState(false)

  const handlePlayPause = (
    e: any,
  ) => {

    e?.stopPropagation?.()

    setTogglePlay((prev) =>
      !prev
    )
  }

  const isMobile = useResponsive('down', 'sm');

  return (
    <Box
      sx={{
        mt: {
          md: 0,
          xs: 2
        },
      }}
    >
      <Card
        onClick={() =>
          push(
            `${occasionPath}occasion/?_id=${_id}`
          )
        }
        sx={{
          cursor: 'pointer',
          position: 'relative',
          height: {
            md: '490px',
            xs: '370px',
          },
          width: {
            md: '470px',
            xs: '343px',
          },
          backgroundImage: `url(${centerFace(
            imagePath,
            isMobile ? 370 : 490,
            isMobile ? 343 : 470
          )})`,
          backgroundSize: 'cover',
          borderRadius: '30px',
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          position="absolute"
          bottom="0%"
          paddingLeft={4}
          paddingRight={2}
          paddingBottom={3}
          sx={{
            border: '0px solid red',
            width: '100%',
            height: '160px',
            background:
              `linear-gradient(
                180deg, 
                rgba(0, 0, 0, 0) -1.23%, 
                #000000 80%
              )`,
          }}
        >
          <Box
            sx={{
              border: '0px solid red',
            }}
          >
            <Box
              display="flex"
              justifyContent="space-between"
              flexDirection="row"
              sx={{
                border: '0px solid red',
              }}
            >
              <Box sx={{ marginTop: 1 }}>
                <Box
                  sx={{ marginTop: 1 }}
                  style={{
                    border: '0px solid red',
                    marginTop: 1,

                    color: common.white,
                  }}
                >
                  <Typography
                    sx={{
                      mt: '24px',
                      border: '0px solid red',
                      fontSize: '32px',
                      fontWeight: 700,
                      color: common.white,
                    }}
                  >
                    {name}
                  </Typography>
                  <Box
                    display="flex"
                    alignItems="center"
                    sx={{
                      fontSize: '14px',
                      marginTop: '20px',
                      color: text.off,
                      border: '0px solid red',
                      transform: 'translateY(10px)',
                    }}
                  >
                    MORE
                    <ArrowRight
                      style={{
                        marginLeft: 10,
                      }}
                      width={25}
                      height={13}
                    />
                    <Box
                      onClick={(e: any) =>
                        e.stopPropagation()
                      }
                      sx={{
                        border: '0px solid red',
                        width: '230px',
                        ml: 2.5,
                        visibility:
                          togglePlay ?
                            'visible'
                            :
                            'hidden'
                      }}
                    >
                      <AudioPlayer
                        onlySeek={true}
                        audioUrl={
                          occasionSong
                            ?.song
                            ?.audioFile
                            ?.path
                        }
                        _id={
                          occasionSong
                            ?.song
                            ?.audioFile
                            ?._id
                          + '-' + _id
                        }
                        togglePlay={togglePlay}
                        setTogglePlay={setTogglePlay}
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
              {occasionSong
                ?.song
                ?.audioFile
                ?.path &&
                <Box
                  onClick={handlePlayPause}
                  sx={{
                    border: '0px solid red',
                  }}
                >
                  {togglePlay ?
                    <StopBtnBox
                      style={{
                        border: '0px solid red',
                        cursor: 'pointer',
                        marginTop: 45,
                      }}
                      width={'95px'}
                      height={'95px'}
                    />
                    :
                    <PlayBtnBox
                      style={{
                        border: '0px solid red',
                        cursor: 'pointer',
                        marginTop: 45,
                      }}
                      width={'95px'}
                      height={'95px'}
                    />
                  }
                </Box>
              }
            </Box>
          </Box>
        </Box>
      </Card>
    </Box>
  );
}

export default OccasionCard;
